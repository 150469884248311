var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "award-mobile" },
    [
      _c(
        "MissionCard",
        { staticClass: "award-mobile__card" },
        [
          _c("ResizableText", {
            staticClass: "h-full w-full",
            attrs: { message: _vm.awardMessage },
          }),
        ],
        1
      ),
      _vm._l(_vm.players, function (player) {
        return _c(
          "div",
          {
            key: "award-" + player.id + "-" + player.teamID,
            staticClass: "award-mobile__user",
          },
          [
            _vm.isTrophyVisible
              ? _c("img", {
                  staticClass: "award-mobile__trophy",
                  attrs: { src: _vm.TROPHY_IMAGE_URL },
                })
              : _vm._e(),
            _c("SocialPlayerCard", {
              key: "award-player-" + player.id + "-" + player.teamID,
              attrs: { user: player },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }