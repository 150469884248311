

























import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import useAward from "./useAward"

const TROPHY_IMAGE_URL = require("@/assets/trophy.png")

export default {
  name: "AwardMobile",
  components: {
    SocialPlayerCard: () =>
      import(
        "@/components/GroupTeams/Common/Player/SocialPlayerCard/SocialPlayerCard.vue"
      ),
    MissionCard,
    ResizableText
  },

  setup() {
    const {
      isPhotoAward,
      instructions,
      isHost,
      awardCandidates,
      awardMessage,
      isTrophyVisible,
      players
    } = useAward()

    return {
      isPhotoAward,
      instructions,
      isHost,
      awardCandidates,
      awardMessage,
      isTrophyVisible,
      players
    }
  },

  data() {
    return {
      TROPHY_IMAGE_URL
    }
  }
}
